import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import {
  DividedSection,
  Badge,
  Title,
  Text,
} from "gatsby-theme-material-foundry"
import EventList from "../components/event-list"
import { Container, Card, Grid, Box, makeStyles } from "@material-ui/core"
import Divider from "@material-ui/core/Divider"
import SEO from "../components/SEO/seo"
import { ThemeProvider } from "@material-ui/styles"
import theme from "../gatsby-theme-material-foundry/theme"
import { ImageDialog } from "../components/form-dialog"
import ShareList from "../components/sharelist"
import BackgroundImage from "gatsby-background-image"

import moment from "moment"
require("moment/locale/nb")

moment.locale("nb")

const useStyles = makeStyles(theme => ({
  hero: {
    height: "500px",
    width: "100%",
    backgroundSize: "cover",
  },
  titleBox: { color: "white", marginTop: "50px" },
}))

function GenerateTags({ tags }) {
  return tags.map(tag => <Badge key={tag}>{tag}</Badge>)
}

function EventTemplate(props) {
  const {
    start,
    slutt,
    slug,
    registreringslenke,
    programUrl,
    mat,
    maksdeltakere,
    facebookevent,
    cover,
    campus,
    beskrivelse,
    adresse,
    tittel,
    tags,
  } = props.data.arrangement
  const backupEventImg = props.data.eventImg.childImageSharp.fixed
  const classes = useStyles()
  const difference = moment(slutt).diff(start, "days")
  const dato =
    difference > 0
      ? `${moment(start).format("dddd, DD")} til ${moment(slutt).format(
          "dddd, DD MMMM YYYY"
        )}`
      : moment(start).format("dddd, DD MMMM YYYY")
  const splitText = beskrivelse.split("\n")

  return (
    <ThemeProvider theme={theme}>
      <Layout>
        <SEO
          postDescription={beskrivelse}
          postTitle={tittel}
          slug={`arrangement/${slug}`}
          datePublished={start}
          postImage={cover ? `${cover}&sz=w1500` : backupEventImg.src}
        />
        <BackgroundImage
          alt={`cover picture`}
          fixed={[
            `linear-gradient(rgba(0, 0, 0, 0.73), rgba(0, 0, 0, 0.6))`,
            cover
              ? {
                  src: `${cover}&sz=w1500`,
                  srcSet: `${cover}&sz=w1500 1x`,
                  width: "100%",
                }
              : backupEventImg,
          ]}
          className={classes.hero}
          backgroundColor={`#040e18`}
        >
          <Container maxWidth="md" className={classes.titleBox}>
            <Box pt={12}>
              <Text variant="subheader">
                <Badge color="primary">Event</Badge>
                <Badge style={{ marginLeft: "10px" }} color="secondary">
                  {campus}
                </Badge>
                {tags && <GenerateTags tags={tags} />}
              </Text>
              <Title variant="h2" gutterBottom>
                {tittel}
              </Title>
              <Text variant="h5">
                {dato} kl {moment(start).format("HH:mm")}-
                {moment(slutt).format("HH:mm")}
              </Text>
            </Box>
          </Container>
        </BackgroundImage>
        <DividedSection minHeight="50vh">
          <Container maxWidth="md">
            <Box py={8}>
              <Grid container>
                <Grid item md={8}>
                  <Box pr={4}>
                
                    <Divider />
                    <Box pt={2}>
                      {splitText.map((p, i) =>
                        p.length > 0 ? (
                          <Box py={1} key={i}>
                            {" "}
                            <Text
                              variant="paragraph"
                              style={{ fontSize: "16px", lineHeight: 1.5 }}
                              gutterBottom
                            >
                              {p}
                            </Text>{" "}
                          </Box>
                        ) : null
                      )}

                      {programUrl && (
                        <Box>
                          <Title variant="h5" gutterBottom black>
                            Program
                          </Title>
                          <ImageDialog
                            title="Program"
                            url={`${programUrl}&sz=w1000`}
                          >
                            {" "}
                            <img
                              src={`${programUrl}&sz=w500`}
                              alt={programUrl}
                            />
                          </ImageDialog>
                        </Box>
                      )}
                    </Box>
                  </Box>
                </Grid>
                <Grid item md={4}>
                  <Card align="center">
                    <Box p={2}>
                      <Title align="center" black>
                        Event Info
                      </Title>

                      <EventList
                        register={registreringslenke}
                        date={
                          difference > 0
                            ? `${moment(start).format("DD")}-${moment(
                                slutt
                              ).format("DD MMMM YYYY")}`
                            : moment(start).format("dddd, DD MMMM YYYY")
                        }
                        time={`${moment(start).format("HH:mm")}-${moment(
                          slutt
                        ).format("HH:mm")}`}
                        location={adresse}
                        food={mat}
                        facebook={facebookevent}
                        maxAttendees={
                          maksdeltakere ? `Maks ${maksdeltakere} stk` : ""
                        }
                      />
                    </Box>
                  </Card>
                  <ShareList url={props.location.href} />
                </Grid>
              </Grid>
            </Box>
          </Container>
        </DividedSection>
      </Layout>
    </ThemeProvider>
  )
}

export default EventTemplate

export const ItemPageQuery = graphql`
  query SingleEvent($itemId: String!) {
    arrangement(id: { eq: $itemId }) {
      start
      slutt
      slug
      registreringslenke
      programUrl
      mat
      maksdeltakere
      facebookevent
      cover
      id
      campus
      beskrivelse
      adresse
      tittel
      tags
    }

    eventImg: file(relativePath: { eq: "event_cover.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fixed(width: 1500) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`
